
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Table,Popover,Modal,Image,Form,Select,Spin} from 'antd';
import { Drawer, List,Alert } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  AccountBookFilled,
  CalculatorFilled,
  FileFilled,
  MessageFilled,
  LoadingOutlined,
  FundViewOutlined
} from '@ant-design/icons';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import UIfx from 'uifx';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'

import logo from '../../assets/Pitech-logoconv.png';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import ReconnectingWebSocket from 'reconnecting-websocket';
import tickAudio from '../../assets/sounds/that-was-quick.mp3';
import IdleTimer from 'react-idle-timer';

var CryptoJS = require("crypto-js");

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

var userbalancenotificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/userbalancenotification/');


const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var sacco= ''
var token= ''


class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    appprofile:{},

    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    date_today:moment().format(dateFormat).toString(),
    dashboardlocked:false,
    userbalancemodalvisible:false,
    users:[],
    userto:'',
    transferamount:0,
    datarequested:false,
    transfermode:'',
    safemanagertransfermode:'',
    pendingtransfers:[],
    numberofnotifications:0,
    notificationinfo:'',
    balanceacceptancemodalvisible:false,
    selectedtransferobject:{},
    acceptancestatus:'',

    isTimedOut: false,
    showtimeoutmodal:false

  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


  //show user balance modal
  showuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: true });
  }

  hideuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: false });
  }

  //show hide balance acceptance modal
  openbalanceacceptancemodalmodal=(record)=>{
    this.setState({ acceptancestatus: ''})
    //console.log(record)
    this.setState({balanceacceptancemodalvisible: true });
    this.setState({selectedtransferobject:record})

  }

  closebalanceacceptancemodalmodal=()=>{
    this.setState({balanceacceptancemodalvisible: false });
  }



//component did mount
  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //component mount method
    this.componentmountmethod();
    

    //socket connection
    userbalancenotificationsocket.onopen = () => {
    console.log("onopen for userbalance notification");
    // connection opened
    };

    userbalancenotificationsocket.onmessage = async (e) => {
        // a message was received
        console.log("onmessage for userbalance",e.data);
        const data = JSON.parse(e.data);
          if(data.action==="onbalancesending"){
            if(Number(data.userto)===Number(bizuserid)){
              this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
              this.setState({notificationinfo:data.info})
              //play notification
              tick.play();

              //component mount method
             this.componentmountmethod();
          
          }else{
            console.log("user id not the same")
          }
          }else if(data.action==="onbalanceconfirming"){

              //component mount method
              this.componentmountmethod();

              if(Number(data.userfrom)===Number(bizuserid)){
                //play notification
                tick.play();

                this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
                this.setState({notificationinfo:data.info})
              }

          }

    };

    userbalancenotificationsocket.onerror = (e) => {
        // an error occurred
        // console.log(e.message);
    };

    userbalancenotificationsocket.onclose = (e) => {
        // connection closed
        // console.log(e.code, e.reason);
    };


  }

//component mount method
componentmountmethod=()=>{
  let form_data = new FormData();
  form_data.append('userid', bizuserid);
  
  //get the user profile here
  axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      this.setState({
        userprofile:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
  .then(res => {
      this.setState({
        companyprofile:res.data
      })

      if (res.data.activated===false){
        this.setState({dashboardlocked:true})
      }
  })

  axios.get(serverconfig.backendserverurl+`/api/companyprofile/${1}`)
  .then(res => {
      this.setState({
        appprofile:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
  .then(res => {
      this.setState({
        users:res.data
      })
    })

  axios.get(serverconfig.backendserverurl+`/api/userbalancetransfer/?userto=${bizuserid}&status=${'pending'}`)
  .then(res => {
    console.log(res.data)
      this.setState({
        pendingtransfers:res.data
      })
    })


}



  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    

  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


  handleusertoChange= (e) => this.setState({ userto: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});
  handletransfermodeChange= (e) => this.setState({ transfermode: e});
  handlesafemanagertransfermodeChange= (e) => this.setState({ safemanagertransfermode: e});
  handleacceptancestatusChange= (e) => this.setState({ acceptancestatus: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{
    if (this.state.userto===''){
      message.info("User To cant be empty")
    }else if(Number(this.state.transferamount)>Number(this.state.userprofile.User_balance)){
      message.info("Transfer Amount cant be greater than available user balance")
    }else if(Number(this.state.userto)===Number(bizuserid)){
      message.info("You cant transfer to own account")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('userfrom', bizuserid);
      form_data.append('userto', this.state.userto);
      form_data.append('transferAmount', this.state.transferamount);
      form_data.append('status', "pending");
      form_data.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/customqueries/userbalancetransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        window.location.reload(false)

      })
      .catch(error => console.log(error))
    }
    

  }

  handlemanagerTransferSubmit=(event) =>{
    if (this.state.safemanagertransfermode===''){
      message.info("Safe Manager Transfer Mode cant be empty")
    }else if(this.state.safemanagertransfermode==="manager_safe" &&  Number(this.state.transferamount)>Number(this.state.userprofile.User_balance)){
      message.info("Transfer Amount cant be greater than available user balance")
    }else if(this.state.safemanagertransfermode==="safe_manager" &&  Number(this.state.transferamount)>Number(this.state.companyprofile.safe_balance)){
      message.info("Transfer Amount cant be greater than available safe balance")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('userid', bizuserid);
      form_data.append('transferAmount', this.state.transferamount);
      form_data.append('sacco', sacco);
      form_data.append('safemanagertransfermode', this.state.safemanagertransfermode);

      axios.post(serverconfig.backendserverurl+'/customqueries/managersafetransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.feedback.message)
        window.location.reload(false)
      })
      .catch(error => console.log(error))
    }

  }

//confirm the transfer here
  receiveUserBalance = () => {
    let form_data1 = new FormData();
    form_data1.append('transferid', this.state.selectedtransferobject.id);
    form_data1.append('sacco', sacco);
    form_data1.append('acceptancestatus', this.state.acceptancestatus);

    axios.post(`${serverconfig.backendserverurl}/customqueries/receiveuserbalance`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
       message.info("successfully response") 
       window.location.reload(false)
     }
     );

  };



   render(){

    var transfercolumns = [

      {
        title: 'User From',
        dataIndex: 'userfromname',
        key: 'id',
      },

      {
        title: 'Transfer Amount',
        dataIndex: 'transferAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
     
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Accept Or Reject</p>} title="Accept Reject">
         <EditOutlined style={{color:'blue'}} onClick={()=>{this.openbalanceacceptancemodalmodal(record)}}/>
        </Popover>
       
        </p>,
      }

    ];


    return (
      <div>

      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={()=>{}}
            onIdle={()=>{
              this.props.logout()

/*           console.log("AM IDLE")

              const isTimedOut = this.state.isTimedOut

              if (isTimedOut) {
              } else {
                  this.setState({showtimeoutmodal: true})
                  this.idleTimer.reset();
                  this.setState({isTimedOut: true})
                }*/

            }}
            onAction={()=>{}}
            debounce={250}
            timeout={this.state.appprofile.sessiontimeoutmilliseconds} />


        {
         this.props.isAuthenticated ?
         <Layout style={{ minHeight: '100vh' }} >
           {
             this.state.dashboardlocked===false?
             <Sider
             collapsedWidth="0"
             collapsed={this.state.collapsed}
             onCollapse={this.onCollapse} 
             theme={this.state.theme}
             breakpoint="lg"
             onBreakpoint={broken => {
               console.log(broken);
             }}
             zeroWidthTriggerStyle={{display:'none'}}
             style={{width:400}}
             >
               <br></br>
              <div className="logo" style={{ paddingLeft: 30,display:'flex',margin:6}} mode="inline">
              <Row>
   
                <Col span={6}>
   {/*               <Avatar size={40} icon={<UserOutlined />} alt="Logo" src={this.state.companyprofile.company_logo} />
           */}     
                <Image
                 width={50}
                 height={50}
                 src={this.state.companyprofile.sacco_logo}   
                 preview={false} 
                 />
                <h6 style={{display:'flex',color:'white',margin:3}}>{this.state.companyprofile.sacco_name}</h6> 
                </Col>
   {/*             <Col span={18}><NavLink to='/'><h5 style={{color:this.state.titlecolor,paddingTop: 10,visibility:this.state.collapsed?"hidden":"visible" }}>{this.state.companyprofile.company_name}</h5></NavLink></Col>
         */}           </Row>
              <br/>
              </div>
   
              <Menu 
              theme={this.state.theme}
              onClick={this.handleClick}
              defaultSelectedKeys={['1']} 
              mode="inline">
   
               <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <HomeOutlined />
                      <span>Home</span>
                    </span>
                  }
                >
               <Menu.Item key="0" >
                 <RightCircleFilled />
                 <NavLink to='/'><span>Home</span></NavLink>
   
               </Menu.Item>
   
   
               </SubMenu>
   
               <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <UserOutlined />
                      <span>Members</span>
                    </span>
                  }
                >
                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="1" >
                  <RightCircleFilled />
                  <NavLink to='/members/'><span>Members</span></NavLink>
                  </Menu.Item>
                  :
                  null
                }

                {
                  this.state.userrights.can_view_members?
                    this.state.companyprofile.showgroupaccounts?
                    <Menu.Item key="39" >
                    <RightCircleFilled />
                    <NavLink to='/groups/'><span>Groups</span></NavLink>
                    </Menu.Item>
                    :
                    null
                  :
                  null
                }                

                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="36" >
                    <RightCircleFilled />
                    <NavLink to='/financialstatement/'><span>Financial Statement</span></NavLink>
                  </Menu.Item>
                  :
                  null
                }


                {
                  this.state.userrights.can_view_members===true && this.state.companyprofile.sharesfeature===true?
                    <Menu.Item key="104" >
                    <RightCircleFilled />
                    <NavLink to='/sharesstatementreport/'><span>Shares Statement</span></NavLink>
                    </Menu.Item>
                  :
                  null
                }


                {
                  this.state.userrights.can_view_members?
                    this.state.companyprofile.interbranchtransactionsactivated===true?
                    <Menu.Item key="41" >
                    <RightCircleFilled />
                    <NavLink to='/interbranchfinancialstatement/'><span>InterBranch-Financial Statement</span></NavLink>
                    </Menu.Item>
                    :
                    null
                    
                  :
                  null
                }
   
               </SubMenu>
   
               <SubMenu
                  key="sub3"
                  title={
                    <span>
                      <AccountBookFilled />
                      <span>Loans</span>
                    </span>
                  }
                >

                {
                  this.state.userrights.can_assess_loans?
                  <Menu.Item key="3" >
                  <RightCircleFilled />
                  <NavLink to='/loanapplications/'><span>Applications</span></NavLink>
                 </Menu.Item>
                  :
                  null
                }                  

               
               {
                  this.state.userrights.can_approve_loans?
                  <Menu.Item key="4" >
                  <RightCircleFilled />
                  <NavLink to='/loanassessments/'><span>Assessments</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                }                

   

               {
                  this.state.userrights.can_issue_loans?
                  <Menu.Item key="5" >
                  <RightCircleFilled />
                  <NavLink to='/loanapprovals/'><span>Approvals</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                }    

   

               {
                  this.state.userrights.viewissuedloans?
                  <Menu.Item key="6" >
                  <RightCircleFilled />
                  <NavLink to='/loanissues/'><span>Disbursements</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                }    

   
               </SubMenu>
   
              
               {
                  this.state.userrights.can_view_expenses?
                      <SubMenu
                      key="sub4"
                      title={
                        <span>
                          <CalculatorFilled />
                          <span>Expenses</span>
                        </span>
                      }
                    >
    
                  <Menu.Item key="7" >
                    <RightCircleFilled />
                    <NavLink to='/expenses/'><span>Expenses</span></NavLink>
                  </Menu.Item>
      
                  </SubMenu>

                  :
                  null
                }               
              

   
              {
                  this.state.userrights.can_view_incomes?
                    <SubMenu
                    key="sub5"
                    title={
                      <span>
                        <DollarCircleOutlined />
                        <span>Incomes</span>
                      </span>
                    }
                  >

                <Menu.Item key="8" >
                  <RightCircleFilled />
                  <NavLink to='/incomes/'><span>Incomes</span></NavLink>
                </Menu.Item>
    
                </SubMenu>
                  :
                  null
                }   


   
                {
                  this.state.userrights.can_view_assets?
                    <SubMenu
                    key="sub6"
                    title={
                      <span>
                        <BankFilled />
                        <span>Assets</span>
                      </span>
                    }
                  >

                <Menu.Item key="9" >
                  <RightCircleFilled />
                  <NavLink to='/assets/'><span>Assets</span></NavLink>
                </Menu.Item>
                </SubMenu>
                  :
                  null
                } 


   
   
                {
                  this.state.userrights.can_view_staff?
                    <SubMenu
                    key="sub7"
                    title={
                      <span>
                        <UserOutlined />
                        <span>Staff</span>
                      </span>
                    }
                  >


                  
                <Menu.Item key="10" >
                  <RightCircleFilled />
                  <NavLink to='/staffs/'><span>Staff</span></NavLink>
                </Menu.Item>
    
                </SubMenu>

                  :
                  null
                }  



               {
                  this.state.userprofile.branch_manager?
                  <SubMenu
                  key="sub8"
                  title={
                    <span>
                      <SettingOutlined />
                      <span>Settings</span>
                    </span>
                  }
                >
               <Menu.Item key="37" >
                 <NavLink to='/companyprofile/'><span>Company Profile</span></NavLink>
               </Menu.Item>
               <Menu.Item key="38" >
                 <NavLink to='/useraccounts/'><span>User Accounts</span></NavLink>
               </Menu.Item>

               <Menu.Item key="45" >
                 <NavLink to='/loggedinusers/'><span>Logged In Accounts</span></NavLink>
               </Menu.Item>

               <Menu.Item key="11" >
                 <NavLink to='/accounttypes/'><span>Account Types</span></NavLink>
               </Menu.Item>
     
               <Menu.Item key="13" >
                 <NavLink to='/loantypes/'><span>Loan Types</span></NavLink>
               </Menu.Item>        
               <Menu.Item key="14" >
                 <NavLink to='/idtypes/'><span>ID Types</span></NavLink>
               </Menu.Item> 
               <Menu.Item key="15" >
                 <NavLink to='/nonworkingdays/'><span>Non Working Days</span></NavLink>
               </Menu.Item> 
               <Menu.Item key="16" >
                 <NavLink to='/staffroles/'><span>Staff Roles</span></NavLink>
               </Menu.Item> 
               <Menu.Item key="17" >
                 <NavLink to='/expensecategories/'><span>Expense Categories</span></NavLink>
               </Menu.Item>           
               <Menu.Item key="18" >
                 <NavLink to='/incomecategories/'><span>Income Categories</span></NavLink>
               </Menu.Item>  
               <Menu.Item key="19" >
                 <NavLink to='/assetcategories/'><span>Asset Categories</span></NavLink>
               </Menu.Item>   
{ /*              <Menu.Item key="21" >
                 <NavLink to='/cashatbank/'><span>Cash At Bank</span></NavLink>
               </Menu.Item>*/ }
               <Menu.Item key="22" >
                 <NavLink to='/externalloans/'><span>External Loans</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="23" >
                 <NavLink to='/bankaccounts/'><span>Bank-Safe Transfers</span></NavLink>
               </Menu.Item> 

                {
                  this.state.companyprofile.allow_transaction_editing===true?
                  <Menu.Item key="120" >
                  <NavLink to='/alltransactions/'><span>All Transactions</span></NavLink>
                  </Menu.Item> 
                  :
                  null
                }

               </SubMenu>
               
                :
                null
              } 

              
   
               <SubMenu
                  key="sub9"
                  title={
                    <span>
                      <FilePdfFilled />
                      <span>Reports</span>
                    </span>
                  }
                >

                {
                  this.state.userrights.viewtill?
                  <Menu.Item key="23" >
                  <NavLink to='/tillsheet/'><span>Till Sheet</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 



               {
                  this.state.userrights.viewdaysheet?
                  <Menu.Item key="24" >
                  <NavLink to='/accounttransactions/'><span>Transactions</span></NavLink>
                  </Menu.Item>
                  :
                  null
                } 

                {
                  this.state.userrights.viewdaysheet?
                  <Menu.Item key="80" >
                  <NavLink to='/accounttransactionschannel/'><span>Transactions (Channel)</span></NavLink>
                  </Menu.Item>
                  :
                  null
                } 

               {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="35" >
                  <NavLink to='/financialstatement/'><span>Financial Statement</span></NavLink>
                 </Menu.Item>                  
                  :
                  null
                } 


                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="786" >
                  <NavLink to='/membersreport/'><span>Members</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                }                  


                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="25" >
                  <NavLink to='/memberbalances/'><span>Member Balances</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                }


                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="112" >
                  <NavLink to='/loanreservereport/'><span>Loan Reserve</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                } 

                  {
                  this.state.userprofile.branch_manager?
                  <Menu.Item key="26" >
                  <NavLink to='/incomestatement/'><span>Income Statement</span></NavLink>
                </Menu.Item>
                  :
                  null
                  }

                {
                  this.state.userprofile.branch_manager && this.state.companyprofile.sharesfeature===true?
                  <Menu.Item key="105" >
                  <NavLink to='/dividendscalculator/'><span>Dividends Calculator</span></NavLink>
                </Menu.Item>
                  :
                  null
                  }


                {
                  this.state.userrights.loandailyreport?
                  <Menu.Item key="27" >
                  <NavLink to='/dayloanreport/'><span>Day Loan Report</span></NavLink>
                  </Menu.Item>           
                  :
                  null
                } 

                {
                  this.state.userrights.outstandingloans?
                  <Menu.Item key="28" >
                  <NavLink to='/loanoutstanding/'><span>Out Standing Report</span></NavLink>
                  </Menu.Item>        
                  :
                  null
                } 


                {
                  this.state.userrights.loanrepaymentstatus?
                  <Menu.Item key="29" >
                  <NavLink to='/loanrepaymentstatus/'><span>Loan Repay(mt) Status</span></NavLink>
                  </Menu.Item>       
                  :
                  null
                } 


                {
                  this.state.userrights.loandefaultsreport?
                  <Menu.Item key="30" >
                  <NavLink to='/loandefaults/'><span>Loan Defaults Report</span></NavLink>
                  </Menu.Item>      
                  :
                  null
                } 

                {
                  this.state.userrights.loandefaultsreport?
                  <Menu.Item key="130" >
                  <NavLink to='/loandailydefaultsreport/'><span>Loan Daily Defaults Report</span></NavLink>
                  </Menu.Item>      
                  :
                  null
                }

                {
                  this.state.userrights.loanagingreport?
                  <Menu.Item key="31" >
                  <NavLink to='/loanaging/'><span>Loan Aging Report</span></NavLink>
                 </Menu.Item>   
                  :
                  null
                } 


                {
                  this.state.userrights.loandisburshmentreport?
                  <Menu.Item key="32" >
                  <NavLink to='/loandisbursement/'><span>Loan Disbursement</span></NavLink>
                 </Menu.Item>
                  :
                  null
                } 


                {
                  this.state.userrights.searchloanrepayments?
                  <Menu.Item key="33" >
                  <NavLink to='/loanrepaymentreport/'><span>Loan Repayments</span></NavLink>
                </Menu.Item>
                  :
                  null
                } 


                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="34" >
                <NavLink to='/balancesheet/'><span>Balance Sheet</span></NavLink>
                </Menu.Item>
                :
                null
                }

                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="107" >
                <NavLink to='/memberperuserreport/'><span>Members Pr User Report</span></NavLink>
                </Menu.Item>
                :
                null
                }

                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="108" >
                <NavLink to='/safebanktransfersreport/'><span>Safe Bank Transfers Report</span></NavLink>
                </Menu.Item>
                :
                null
                }

                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="109" >
                <NavLink to='/safeusertransfersreport/'><span>Safe User Transfers Report</span></NavLink>
                </Menu.Item>
                :
                null
                }

                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="110" >
                <NavLink to='/saccosmsusagestatement/'><span>SMS Usage Report</span></NavLink>
                </Menu.Item>
                :
                null
                }


                {
                this.state.userprofile.branch_manager?
                this.state.companyprofile.userbalancesfeature?
                <Menu.Item key="40" >
                <NavLink to='/userbalances/'><span>Balances Transfer Report</span></NavLink>
                </Menu.Item>
                :
                null
                :
                null
                }

                {
                  this.state.userprofile.branch_manager?
                  this.state.companyprofile.interbranchtransactionsactivated===true?
                  <Menu.Item key="42" >
                  <NavLink to='/interbranchtransactionsreport/'><span>InterBranch Transactions Report</span></NavLink>
                  </Menu.Item>
                  :
                  null
                  :
                  null
                }

                {
                  this.state.userprofile.branch_manager?
                  this.state.companyprofile.interbranchtransactionsactivated===true?
                  <Menu.Item key="43" >
                  <NavLink to='/interbranchmoneytransferreport/'><span>InterBranch Money Transfers Report</span></NavLink>
                  </Menu.Item>
                  :
                  null
                  :
                  null
                }

                {
                  this.state.userprofile.branch_manager===true && this.state.companyprofile.periodic_member_summaryreport===true?
                  <Menu.Item key="435665" >
                  <NavLink to='/periodicmember_summaryreport/'><span>Periodic member summary report</span></NavLink>
                  </Menu.Item>
                  :
                  null
                }


               </SubMenu>
   
              </Menu>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
   { /*          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <br></br>
                 <Switch
                    checked={this.state.theme === 'dark'}
                    onChange={this.changeTheme}
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                  />
              <br></br>
                 </div> */}
   
            </Sider>
             :
             null
           }
        
         <Layout className="site-layout">
   
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[
                <Badge  onClick={()=>{this.showuserbalancemodalvisible()}}  pill color="danger">{this.state.numberofnotifications}</Badge>,
                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={(    <Menu >
                  <Menu.Item key="1" onClick={this.props.logout}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.showDrawer}>
                    <UserOutlined />
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.showpasswordresetModal}>
                    <KeyOutlined />
                    Change Password
                  </Menu.Item>
                  {
                    this.state.companyprofile.userbalancesfeature?
                    <Menu.Item key="4" onClick={this.showuserbalancemodalvisible}>
                    <DollarCircleOutlined />
                    User Balance
                    </Menu.Item>        
                    :
                    null
                  }
            
                </Menu>)}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        


           <Content style={{ margin: '0 16px' }}>
             <br></br>
             {
               this.state.notificationinfo===""?
               null
               :
               <NavLink >
                <Alert showIcon message={this.state.notificationinfo} type="info" onClick={()=>{this.showuserbalancemodalvisible()}} />
               </NavLink>


             }

             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
       </Layout>
         :
         <Layout style={{ minHeight: '100vh' }}>
         <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}>
             {this.props.children}
           </div>
         </Content>
         <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id} username={this.state.userprofile.username} email={this.state.userprofile.email}/>       

          </div>

        </Drawer>


        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>

        <Drawer
          title={
          <h4>User Balance: <CurrencyFormat value={this.state.userprofile.User_balance} displayType={'text'} thousandSeparator={true}  /></h4>}
          width={520}
          placement="right"
          closable={false}
          onClose={this.hideuserbalancemodalvisible}
          visible={this.state.userbalancemodalvisible}
        >
          {
            this.state.pendingtransfers.length>0?
            <div>
            <h2>Pending Approvals</h2>
            <Table   
              columns={transfercolumns} 
              scroll={{ x: 1000 }}
              dataSource={this.state.pendingtransfers} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              bordered/>
            </div>
            :
            null
          }


        <h2>User Balance Transfer Form</h2>

          <FormItem 
                label="User Balance Transfer Mode"
                name="transfermode"
                rules={[
                  {
                    required: true,
                    message: 'Please select transfer mode',
                  },
                ]}
                >
                    <Select 
                    value={this.state.transfermode} 
                    onChange={this.handletransfermodeChange}
                    placeholder="Transfer Mode" >
                          <Option value='usertouser'>User To User</Option>
                          {
                            this.state.userprofile.branch_manager?
                            <Option value='usersafe'>User Safe</Option>
                            :
                            null
                          }
                    </Select>
          </FormItem>

          {
            this.state.transfermode==="usertouser"?
            <Form
              onFinish={(event) => this.handleFormSubmit(event)}
            >

            <FormItem label={<h6 style={{fontWeight:'bold'}}>User To </h6>} >
                    <Select 
                    placeholder="User"
                    style={{ width: 200 }} 
                    value={this.state.userto} 
                    onChange={this.handleusertoChange}
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    >
                      {this.state.users.map(
                        (user)=>(
                          <Option value={user.id}>{user.username}</Option>
                        ))}
                    </Select>
                </FormItem>

                <FormItem label={<h6 style={{fontWeight:'bold'}}>Transfer Amount </h6>}
                      name="transferamount"
                      rules={[
                        {
                          required: false,
                          message: 'Please input amount',
                        },
                      ]}
                      
                      >
                        <InputNumber
                          style={{ width: 200 }} 
                          defaultValue={0.0}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\x\s?|(,*)/g, '')}
                          onChange={this.handletransferamountChange}
                          value={this.state.transferamount}
                        />    
                                    
                </FormItem>

                {
                  this.state.datarequested?
                  <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                  <Spin indicator={antIcon} />
                  </div>  :
                  null
                }

                <div style={{display:'flex',alignSelf:'center',alignContent:'center',alignItems:'center'}}> 
                <FormItem >
                      <Button  type="primary" htmlType="submit">Transfer</Button>
                </FormItem>
                </div>

          </Form>
            :
            null

          }
         

        
        {
          this.state.transfermode==="usersafe"?
          this.state.userprofile.branch_manager?
          <div>
          <Form
            onFinish={(event) => this.handlemanagerTransferSubmit(event)}
          >
             <h4>Safe Balance: <CurrencyFormat value={this.state.companyprofile.safe_balance} displayType={'text'} thousandSeparator={true}  /></h4>
              <FormItem label={<h6 style={{fontWeight:'bold'}}>Transfer Amount </h6>}
                    name="transferamount"
                    rules={[
                      {
                        required: false,
                        message: 'Please input amount',
                      },
                    ]}
                    
                    >
                      <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={this.handletransferamountChange}
                        value={this.state.transferamount}
                      />    
                                  
              </FormItem>

              <FormItem 
                label={<h6 style={{fontWeight:'bold'}}>Safe Manager Transfer Mode </h6>}
                rules={[
                  {
                    required: true,
                    message: 'Please select mode',
                  },
                ]}
                >
                    <Select 
                    value={this.state.safemanagertransfermode} 
                    onChange={this.handlesafemanagertransfermodeChange}
                    placeholder="Transfer Mode" >
                          <Option value='manager_safe'>Manager-Safe</Option>
                          <Option value='safe_manager'>Safe-Manager</Option>
                    </Select>
             </FormItem>


              {
                this.state.datarequested?
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                <Spin indicator={antIcon} />
                </div>  :
                null
              }

              <div style={{display:'flex',alignSelf:'center',alignContent:'center',alignItems:'center'}}> 
              <FormItem >
                    <Button  type="primary" htmlType="submit">Transfer</Button>
              </FormItem>
              </div>

         </Form>


         <Modal              
          visible={this.state.showtimeoutmodal}
          title={<h3>You Have Been Idle!</h3>}
          width={600}
          onCancel={()=>{
            this.setState({showtimeoutmodal:false})
          }}
      
          footer={[
            <Button key="back" type='default' onClick={()=>{
              this.props.logout()
            }}>
              Logout
            </Button>,

            <Button type='primary' key="back" onClick={()=>{this.setState({showtimeoutmodal:false})}}>
            Stay
            </Button>

            ]}
            >
              
              <h5>You Will Get Timed Out. You want to stay?</h5>
                   
          </Modal>
 
          </div>
 
          :
          null
          :
          null

          
        }



        </Drawer>


        <Modal    
          visible={this.state.balanceacceptancemodalvisible}
          title="Balance Acceptance Modal"
          onCancel={this.closebalanceacceptancemodalmodal}
      
          footer={[
            <Button key="back" onClick={this.closebalanceacceptancemodalmodal}>
              Cancel
            </Button>
            ]}
            >
          <h6 style={{fontWeight:'bolder'}}>Accept or Reject {this.state.selectedtransferobject.transferAmount} from {this.state.selectedtransferobject.userfromname}</h6>
          <FormItem 
                label={<h6 style={{fontWeight:'bold'}}>Status </h6>}
                >
                    <Select 
                    value={this.state.acceptancestatus} 
                    onChange={this.handleacceptancestatusChange}
                    placeholder="Acceptance Status" >
                          <Option value='accept'>Accept</Option>
                          <Option value='reject'>Reject</Option>
                    </Select>
          </FormItem>
          <FormItem >
            <Button  type="primary" onClick={()=>{this.receiveUserBalance()}}>Submit</Button>
          </FormItem>

        </Modal>   


        <Modal              
          visible={this.state.showtimeoutmodal}
          title={<h3>You Have Been Idle!</h3>}
          width={600}
          onCancel={()=>{
            this.setState({showtimeoutmodal:false})
          }}
      
          footer={[
            <Button key="back" type='default' onClick={()=>{
              this.props.logout()
            }}>
              Logout
            </Button>,

            <Button type='primary' key="back" onClick={()=>{this.setState({showtimeoutmodal:false})}}>
            Stay
            </Button>

            ]}
            >
              
              <h5>You Will Get Timed Out. You want to stay?</h5>
                   
      </Modal>



      </div>
  );

  }

}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;

  
