import React from 'react';
import { Form, Input, Button,Select,Spin,Switch } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''

class LoanTypeForm extends React.Component {

  state = {
    loan_type_name:'',
    loan_form_fees:0,
    loan_assessment_fees:0,
    loan_insurance_fee:0,
    loan_commitment_fee:0,
    loan_agreement_fees:0,
    reserve_fees:0,
    interest_rate:0,
    maximum_loan_amount:0,
    maximum_loan_amount_first_time_borrower:0,
    maximum_period:0,
    maximum_period_first_time_borrower:0,
    percentage_payment_of_currentloan_before_top_up:0,

    generate_dailyschedule:false,
    loan_repaymentmode:'perschedule',
    interestrate_type:'flatinterest',
    loan_primary_schedule:'monthly'

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('loan_type_name', this.state.loan_type_name);
    form_data.append('loan_form_fees', this.state.loan_form_fees);
    form_data.append('loan_assessment_fees', this.state.loan_assessment_fees);
    form_data.append('loan_insurance_fee', this.state.loan_insurance_fee);
    form_data.append('loan_commitment_fee', this.state.loan_commitment_fee);
    form_data.append('loan_agreement_fees', this.state.loan_agreement_fees);
    form_data.append('reserve_fees', this.state.reserve_fees);
    form_data.append('interest_rate', this.state.interest_rate);
    form_data.append('maximum_loan_amount', this.state.maximum_loan_amount);
    form_data.append('maximum_loan_amount_first_time_borrower', this.state.maximum_loan_amount_first_time_borrower);
    form_data.append('maximum_period', this.state.maximum_period);
    form_data.append('maximum_period_first_time_borrower', this.state.maximum_period_first_time_borrower);
    form_data.append('percentage_payment_of_currentloan_before_top_up', this.state.percentage_payment_of_currentloan_before_top_up);
    form_data.append('sacco', sacco);
    form_data.append('generate_dailyschedule', false);

    form_data.append('loan_repaymentmode', this.state.loan_repaymentmode);
    form_data.append('interestrate_type', this.state.interestrate_type);
    form_data.append('loan_primary_schedule', this.state.loan_primary_schedule);

    axios.post(serverconfig.backendserverurl+'/api/loantypes/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         this.setState({datarequested:false})
,  window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

 
 
  handleloan_type_nameChange= (e) => this.setState({ loan_type_name: e.target.value});
  handleloan_form_feesChange= (e) => this.setState({ loan_form_fees: e.target.value});
  handleloan_assessment_feesChange= (e) => this.setState({ loan_assessment_fees: e.target.value});
  handleloan_insurance_feeChange= (e) => this.setState({ loan_insurance_fee: e.target.value});
  handleloan_commitment_feeChange= (e) => this.setState({ loan_commitment_fee: e.target.value});
  handleloan_agreement_feesChange= (e) => this.setState({ loan_agreement_fees: e.target.value});
  handlereserve_feesChange= (e) => this.setState({ reserve_fees: e.target.value});
  handleinterest_rateChange= (e) => this.setState({ interest_rate: e.target.value});
  handlemaximum_loan_amountChange= (e) => this.setState({ maximum_loan_amount: e.target.value});
  handlemaximum_loan_amount_first_time_borrowerChange= (e) => this.setState({ maximum_loan_amount_first_time_borrower: e.target.value});
  handlemaximum_periodChange= (e) => this.setState({ maximum_period: e.target.value});
  handlemaximum_period_first_time_borrowerChange= (e) => this.setState({ maximum_period_first_time_borrower: e.target.value});
  handlepercentage_payment_of_currentloan_before_top_upChange= (e) => this.setState({ percentage_payment_of_currentloan_before_top_up: e.target.value});




  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="Loan Type Name"
              name="loan_type_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}             
            >
              <Input name="loan_type_name"  placeholder="Put a name here." value={this.state.loan_type_name} onChange={this.handleloan_type_nameChange} />
            </FormItem>

            <FormItem label="Loan Form Fees"
              name="loan_form_fees"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="loan_form_fees"  placeholder="Block Figure eg 1000" value={this.state.loan_form_fees} onChange={this.handleloan_form_feesChange} />
            </FormItem>

  
            <FormItem label="Loan Assessment Fees"
              name="loan_assessment_fees"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="loan_assessment_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_assessment_fees} onChange={this.handleloan_assessment_feesChange} />
            </FormItem>
  
            <FormItem label="Loan Insurance Fees"
              name="loan_insurance_fee"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="loan_insurance_fee"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_insurance_fee} onChange={this.handleloan_insurance_feeChange} />
            </FormItem>


            <FormItem label="Loan Commitment Fee"
              name="loan_commitment_fee"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="loan_commitment_fee"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_commitment_fee} onChange={this.handleloan_commitment_feeChange} />
            </FormItem>


            <FormItem label="Loan Agreement Fees"
              name="loan_agreement_fees"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="loan_agreement_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_agreement_fees} onChange={this.handleloan_agreement_feesChange} />
            </FormItem>

            <FormItem label="Loan Reserve Fee"
              name="reserve_fees"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="reserve_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.reserve_fees} onChange={this.handlereserve_feesChange} />
            </FormItem>

            <FormItem label="Interest Rate"
              name="interest_rate"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="interest_rate"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.interest_rate} onChange={this.handleinterest_rateChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount"
              name="maximum_loan_amount"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_loan_amount"  placeholder="0.00" value={this.state.maximum_loan_amount} onChange={this.handlemaximum_loan_amountChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount (First Time Borrower)"
              name="maximum_loan_amount_first_time_borrower"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_loan_amount_first_time_borrower"  placeholder="0.00" value={this.state.maximum_loan_amount_first_time_borrower} onChange={this.handlemaximum_loan_amount_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Maximum Loan Period"
              name="maximum_period"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_period"  placeholder="0" value={this.state.maximum_period} onChange={this.handlemaximum_periodChange} />
            </FormItem>

            <FormItem label="Maximum Loan Period (First Time Borrower)"
              name="maximum_period_first_time_borrower"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_period_first_time_borrower"  placeholder="0.00" value={this.state.maximum_period_first_time_borrower} onChange={this.handlemaximum_period_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Percentage Payment Before Top App"
              name="percentage_payment_of_currentloan_before_top_up"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="percentage_payment_of_currentloan_before_top_up"  placeholder="Enter converted value eg 0.5 for 50%" value={this.state.percentage_payment_of_currentloan_before_top_up} onChange={this.handlepercentage_payment_of_currentloan_before_top_upChange} />
            </FormItem>


            <FormItem 
                label="Interest rate Type"
                >
                    <Select 
                    value={this.state.interestrate_type} 
                    onChange={(val)=>{this.setState({interestrate_type:val})}}
                    placeholder="Interest rate Type" >
                      <Option value='flatinterest'>Flat Interest</Option>
                      <Option value='reducingBalanceInterest'>Reducing Balance</Option>
                    </Select>
            </FormItem>

            <FormItem 
                label="Loan Primary Schedule (Interest per day/weekly/monthly)"
                >
                    <Select 
                    value={this.state.loan_primary_schedule} 
                    onChange={(val)=>{this.setState({loan_primary_schedule:val})}}
                    placeholder="Loan Primary Schedule" >
                      <Option value='monthly'>Monthly</Option>
                      <Option value='weekly'>Weekly</Option>
                      <Option value='daily'>Daily</Option>
                    </Select>
            </FormItem>


            <FormItem name="generate_dailyschedule" label="Generate Daily Schedule" >
                <Switch
                    checked={this.state.generate_dailyschedule}
                    onChange={val=>{this.setState({generate_dailyschedule:!this.state.generate_dailyschedule})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
            </FormItem>  
 

            <FormItem 
            label="Loan RePayment Mode"
            >
              <Select 
              value={this.state.loan_repaymentmode} 
              onChange={(val)=>{this.setState({loan_repaymentmode:val})}}
              placeholder="Payment Mode" >
                    <Option value='daily'>Daily</Option>
                    <Option value='perschedule'>Per Schedule</Option>
              </Select>
            </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );


    }


    
  }

}


export default LoanTypeForm;



