import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,LoadingOutlined,FilePdfFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import  LoanImportForm from '../components/LoanImportForm'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;


var sacco= ''
var bizuserid= ''
var token= ''

class LoanIssuanceList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanissues: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    inactiveloanissues: [],


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })

    axios.get(serverconfig.backendserverurl+`/api/loanissues/?sacco=${sacco}&active_loan=${true}`)
    .then(res => {
        this.setState({
          loanissues:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/loanissues/?sacco=${sacco}&active_loan=${false}`)
    .then(res => {
        this.setState({
          inactiveloanissues:res.data
        })

        this.setState({datarequested:false})
    })

}


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
      let form_data = new FormData();
      form_data.append('loanid', id);
      form_data.append('sacco',sacco);
      
      axios.post(serverconfig.backendserverurl+'/customqueries/deleteloan', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => window.location.reload(false),message.info('Success fully deleted Loan')
    )
    .catch(error => console.log(error))
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan Amount',
          dataIndex: 'approved_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Issuance Date',
          dataIndex: 'date',
          key: 'id',
        },
        {
          title: 'Loan Period',
          dataIndex: 'loan_period',
          key: 'id',
          render:(text,record)=><h5>{text} ({record.loan_primary_schedule})</h5>

        },
        
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to view details(Schedule & Statement)</p>} title="View">
           <Link to={`/loanissues/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete loan</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
          </Popconfirm>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          {
              this.state.userprofile.branch_manager?
              <Popover content={<p>click here to deactivate paid off loan </p>} title="Deactivate">
                <Popconfirm title="Confirm Pay Off?" onConfirm={() => {
                      this.setState({datarequested:true})

                      let form_data = new FormData();
                      form_data.append('issued_loan_id',record.id);
          
                      //get the user profile here
                      axios.post(serverconfig.backendserverurl+'/customqueries/deactivateactiveloan',form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                      .then(res => {
                          this.setState({datarequested:false})
                          console.log(res.data)
                          window.location.reload(false)
                      })
                }}>
                  <FundViewOutlined style={{color:'blue'}} />
                </Popconfirm>
   
             </Popover>
          :
          null
          }

          </p>,
        }
  
      ];


      var inactiveloancolumns = [

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan Amount',
          dataIndex: 'approved_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Issuance Date',
          dataIndex: 'date',
          key: 'id',
        },
        {
          title: 'Loan Period',
          dataIndex: 'loan_period',
          key: 'id',
        },
        
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to view details(Schedule & Statement)</p>} title="View">
           <Link to={`/loanissues/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          </p>,
        }
  
      ];
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
 <Tabs defaultActiveKey="1" onChange={callback}>
    <TabPane tab="Active Loans" key="1">
    <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ALL ISSUED LOANS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanissues} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />
            {
              this.state.userprofile.branch_manager?
              <div>
              <h3 style={{fontWeight:'bolder'}}>Import Loans here</h3>
              <LoanImportForm />
              </div>
              :
              null
            }

            </Panel>
            </Collapse>
    </TabPane>
    <TabPane tab="Inactive Fully Paid" key="2">
    <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ALL ISSUED LOANS</h4>} key="1">
            <Table   
            columns={inactiveloancolumns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.inactiveloanissues} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />
            {
              this.state.userprofile.branch_manager?
              <div>
              <h3 style={{fontWeight:'bolder'}}>Import Loans here</h3>
              <LoanImportForm />
              </div>
              :
              null
            }

            </Panel>
            </Collapse>

    </TabPane>
 </Tabs>

        </div>
    )


    }


    
  }
}

export default LoanIssuanceList; 
