import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class AccountExtraOperationsView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    limitedwithdrawamount:0,
    
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    deactivate:'no'

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

    })

    //get last slip number
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/customqueries/getsaccolastslipnumber', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({lastslipnumber:res.data.lastnumber})
        this.setState({slipnumber:Number(res.data.lastnumber)+1})

        this.setState({datarequested:false})
    })
    .catch(error => console.log(error))

  }

  
  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/transactions/?transaction_account=${e}`)
      .then(res => {  
          this.setState({previoustransactions: res.data.slice(0, 3)})
          console.log(res.data)
          this.setState({accountchange:false});
      })

      this.setState({slipnumber:Number(this.state.lastslipnumber)+1})

  }


  //submit the deposit now
  handleFormSubmit=(event) =>{

    if(this.state.selectedaccount===""){
      message.info("Member Account missing please")

    }
    else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('deactivate', this.state.deactivate);
      form_data.append('limitedwithdrawamount', this.state.limitedwithdrawamount);
      form_data.append('account', this.state.selectedaccountobject.id);

      axios.post(serverconfig.backendserverurl+'/customqueries/accountextraoperation', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    
    }
  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Deposited "
                    //subTitle="Please print receipt or finish off transaction"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                    </Card>

                  </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>

                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account Deactivate / Limit</h4>
                    <FormItem label="Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }


                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>Minimum Account Deposit:<CurrencyFormat value={this.state.selectedaccountobject.minimum_account_deposit} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>Account Withdraw Limit:<CurrencyFormat value={this.state.selectedaccountobject.withdrawlimit} displayType={'text'} thousandSeparator={true}  /> UGX</h4>

                    <h4>Status:{this.state.selectedaccountobject.account_status}</h4>
  
                    <br></br>
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>DEACTIVATE FORM</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >

                    <FormItem label={<h6 style={{fontWeight:'bold'}}>Limited Withraw Amount </h6>}
                    name="limitedwithdrawamount"
                    rules={[
                      {
                        required: false,
                        message: 'Please input amount',
                      },
                    ]}
                    
                    >
                      <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={(val)=>{this.setState({limitedwithdrawamount:val})}}
                      />    
                                  
                    </FormItem>
                    <br></br>
                    <h3 style={{display:'flex',alignSelf:'center',justifyContent:'center'}}>OR</h3>
                    <br></br>

                    <FormItem 
                      label=" Deactivate Account ?"

                      >
                          <Select 
                          value={this.state.deactivate} 
                          onChange={(val)=>{this.setState({deactivate:val})}}
                          placeholder="" >
                                <Option value='no'>No</Option>
                                <Option value='yes'>Yes</Option>
                          </Select>
                   </FormItem>

                  <FormItem>
                    <Button  type="primary" htmlType="submit">Submit</Button>
                  </FormItem>
                  </Form>
  
                  </Card>
                   
                  </Col>
  
            </Row>
  
            </div>
  
       </div>              
                      
        )
      }
     
    }
   
  }
}

export default AccountExtraOperationsView; 
