import React from 'react';
import LoanApplicationForm from '../components/LoanApplicationForm'
import LoanAssessmentForm from '../components/LoanAssessmentForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''
var bizuserid= ''

class LoanAssessmentList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanapplications: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&assessed=${true}&passed=${false}&issued=${false}&approval_yet=${false}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })

        this.setState({datarequested:false})
    })

}


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/loanapplications/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Request Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Collateral',
          dataIndex: 'collateral_one',
          key: 'id',
        },
        {
          title: 'Collateral Value',
          dataIndex: 'collateral_one_value',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/loanapplications/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to Approve Loan</p>} title="Approve">
            <Link to={`/approveloan/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>
          </p>,
        }
  
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ASSESSED LOAN APPLICATIONS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanapplications} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />
            </Panel>
            </Collapse>

        </div>
    )


    }


    
  }
}

export default LoanAssessmentList; 
