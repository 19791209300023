import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input,Card, Button,Collapse,Spin,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
           <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>

                  </Col>

           </Row>

             <h1 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>BALANCE SHEET AS AT {this.props.date}</h1>
             <h4>NON CURRENT ASSETS </h4>
           <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset Category</th>
                      <th>Net Book Value</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.props.assets.map(
                    (item)=>(
                      <tr>
                      <td>{item.asset_name}</td>
                      <td>{item.categoryname}</td>
                      <td>{<CurrencyFormat value={item.net_book_value} displayType={'text'} thousandSeparator={true}/>}</td>
                      </tr>
                    ))}
                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total Non Current Assets</td>
                    <td></td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalnoncurrentassets} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                </tbody>
            </reactstrp.Table> 


            <Divider style={{borderColor:'red'}} dashed />
            <h4>CURRENT ASSETS </h4>
            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Value (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>Cash At Hand (Safe)</td>
                    <td>{<CurrencyFormat value={this.props.companyprofile.safe_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                    <tr>
                    <td>Cash At Bank</td>
                    <td>{<CurrencyFormat value={this.props.cashatbanktotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>  

                    <tr>
                    <td>Interest Receivable</td>
                    <td>{<CurrencyFormat value={this.props.totalinterestreceivable} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr> 

                    <tr>
                    <td>Loan Portfolio</td>
                    <td>{<CurrencyFormat value={this.props.totalprinciplebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td>Less Reserve (Portfolio AT Risk)</td>
                    <td>{<CurrencyFormat value={this.props.totalRiskAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td>Net Portfolio</td>
                    <td>{<CurrencyFormat value={this.props.totalprinciplebalance-this.props.totalRiskAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td>Account Receivable(Client Debts from Incomes)</td>
                    <td>{<CurrencyFormat value={this.props.totalincomes-this.props.totalincomepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total current Assets</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat 
                    value={this.props.companyprofile.safe_balance+
                      this.props.cashatbanktotal+this.props.totalinterestreceivable+(this.props.totalprinciplebalance-this.props.totalRiskAmount)+
                      (this.props.totalincomes-this.props.totalincomepayments)
                    
                    } displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td style={{fontWeight:'bolder',fontStyle:'italic',color:'blue'}}>TOTAL ASSETS</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={(this.props.companyprofile.safe_balance+
                      this.props.cashatbanktotal+this.props.totalinterestreceivable+(this.props.totalprinciplebalance-this.props.totalRiskAmount)+
                      (this.props.totalincomes-this.props.totalincomepayments))+this.props.totalnoncurrentassets} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>                    
                </tbody>
            </reactstrp.Table> 

            <Divider style={{borderColor:'red'}} dashed />
            <h2 style={{display: 'flex',  justifyContent:'center', alignItems:'center',color:'red' }}>FINANCED BY</h2>
            
            <h3>EQUITY AND LIABILITIES </h3>
            <Divider style={{borderColor:'red'}} dashed />
            <h4>LIABILITIES</h4>
            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Value (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>External Loan</td>
                    <td>{<CurrencyFormat value={this.props.externalloantotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                    <tr>
                    <td>Institutional Debts (From Expenses)</td>
                    <td>{<CurrencyFormat value={this.props.totalexpenses-this.props.totalexpensepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr> 

                    <tr>
                    <td>Member Savings</td>
                    <td>{<CurrencyFormat value={this.props.membersavingstotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>  

                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total Liabilities</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.externalloantotal+(this.props.totalexpenses-this.props.totalexpensepayments)+this.props.membersavingstotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>                    
                </tbody>
            </reactstrp.Table> 


              <Divider style={{borderColor:'red'}} dashed />
              <h4>EQUITY</h4>

              <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Value (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>Institutional Capital (Shares) </td>
                    <td>{<CurrencyFormat value={(this.props.companyprofile.sharevalue*this.props.membersharestotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                    <tr>
                    <td>Retained Earnings</td>
                    <td>{<CurrencyFormat value={this.props.companyprofile.retained_earnings} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr> 

                    <tr>
                    <td>Surplus</td>
                    <td>{<CurrencyFormat value={(this.props.yearlyincometotal-this.props.yearlyexpensetotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>  
                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total Equity</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={(this.props.companyprofile.sharevalue*this.props.membersharestotal)+this.props.companyprofile.retained_earnings+(this.props.yearlyincometotal-this.props.yearlyexpensetotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>   
                    <tr>
                    <td style={{fontWeight:'bolder',fontStyle:'italic',color:'blue'}}>TOTAL EQUITY & LIABILITIES</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={(this.props.externalloantotal+(this.props.totalexpenses-this.props.totalexpensepayments)+this.props.membersavingstotal)+(this.props.companyprofile.sharevalue*this.props.membersharestotal)+this.props.companyprofile.retained_earnings+(this.props.yearlyincometotal-this.props.yearlyexpensetotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>                   
                </tbody>
            </reactstrp.Table>             
        </div>
      );
    }
  }



class BalanceSheetReport extends React.Component {

  state = {
   assets: [],
   totalnoncurrentassets:0,   
   cashatsafe:0,
   cashatbanktotal:0,
   companyprofile:{},
   clientdebttotal:0,
   companydebttotal:0,
   date:moment().format(dateFormat).toString(),
   year:moment().format('YYYY').toString(),
   datarequested:true,
   externalloantotal:0,
   membersavingstotal:0,
   membersharestotal:0,
   totalinterestreceivable:0,
   totalprinciplebalance:0,
   totalRiskAmount:0,
   totalincomes:0,
   totalincomepayments:0,
   totalexpenses:0,
   totalexpensepayments:0,
   yearlyexpensetotal:0,
   yearlyincometotal:0

  };


  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/assets/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          assets:res.data
        })

        const total = res.data.reduce((Total, item) => Total + item.net_book_value, 0);
        this.setState({totalnoncurrentassets:total})
    })

    axios.get(serverconfig.backendserverurl+`/api/bankaccounts/?sacco=${sacco}`)
    .then(res => {
        const total = res.data.reduce((Total, item) => Total + item.balance, 0);
        this.setState({cashatbanktotal:total})
    })

    axios.get(serverconfig.backendserverurl+`/api/externalloans/?sacco=${sacco}`)
    .then(res => {
        const total = res.data.reduce((Total, item) => Total + item.loanbalance, 0);
        this.setState({externalloantotal:total})
    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        const total = res.data.reduce((Total, item) => Total + item.UserBalance, 0);
        this.setState({membersavingstotal:total})

        const totalshares=res.data.reduce((Total, item) => Total + item.sharebalance, 0);
        this.setState({membersharestotal:totalshares})
    })


    axios.get(serverconfig.backendserverurl+`/api/loanissues/?sacco=${sacco}&fully_paid=${false}&active_loan=${true}`)
    .then(res => {
        const total = res.data.reduce((Total, item) => Total + item.interest_balance, 0);
        this.setState({totalinterestreceivable:total})

        const totalprinciple= res.data.reduce((Total, item) => Total + item.principle_balance, 0);
        this.setState({totalprinciplebalance:totalprinciple})

      })

      axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
      .then(res => {
          const total = res.data.reduce((Total, item) => Total + item.total_to_be_paid, 0);
          this.setState({totalincomes:total})
      })

      axios.get(serverconfig.backendserverurl+`/api/incomepayments/?sacco=${sacco}`)
      .then(res => {
          const total = res.data.reduce((Total, item) => Total + item.amount, 0);
          this.setState({totalincomepayments:total})
      })

      axios.get(serverconfig.backendserverurl+`/api/expenses/?sacco=${sacco}`)
      .then(res => {
          const total = res.data.reduce((Total, item) => Total + item.total_to_be_paid, 0);
          this.setState({totalexpenses:total})
      })

      axios.get(serverconfig.backendserverurl+`/api/expensepayments/?sacco=${sacco}`)
      .then(res => {
          const total = res.data.reduce((Total, item) => Total + item.amount, 0);
          this.setState({totalexpensepayments:total})
      })

      axios.get(serverconfig.backendserverurl+`/api/expenses/?sacco=${sacco}&year=${this.state.year}`)
      .then(res => {
          const total = res.data.reduce((Total, item) => Total + item.total_to_be_paid, 0);
          this.setState({yearlyexpensetotal:total})
      })

      axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}&year=${this.state.year}`)
      .then(res => {
          const total = res.data.reduce((Total, item) => Total + item.total_to_be_paid, 0);
          this.setState({yearlyincometotal:total})
      })



      let form_data = new FormData();
      form_data.append('sacco', sacco);
      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getLoanAgingReport', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        this.setState({totalRiskAmount:res.data.totalRisk})
      } 
      )
      .catch(error => console.log(error)) 


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({datarequested:false})
    })

}



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )


    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            assets={this.state.assets} 
            totalnoncurrentassets={this.state.totalnoncurrentassets}
            cashatsafe={this.state.cashatsafe}
            cashatbanktotal={this.state.cashatbanktotal}
            companyprofile={this.state.companyprofile}
            clientdebttotal={this.state.clientdebttotal}
            companydebttotal={this.state.companydebttotal}
            date={this.state.date}
            year={this.state.year}
            externalloantotal={this.state.externalloantotal}
            membersavingstotal={this.state.membersavingstotal}
            membersharestotal={this.state.membersharestotal}
            totalinterestreceivable={this.state.totalinterestreceivable}
            totalprinciplebalance={this.state.totalprinciplebalance}
            totalRiskAmount={this.state.totalRiskAmount}
            totalincomes={this.state.totalincomes}
            totalincomepayments={this.state.totalincomepayments}
            totalexpenses={this.state.totalexpenses}
            totalexpensepayments={this.state.totalexpensepayments}
            yearlyexpensetotal={this.state.yearlyexpensetotal}
            yearlyincometotal={this.state.yearlyincometotal}
            ref={el => (this.componentRef = el)} /> 

          </div>

            <div style={{overflowX:'auto'}}>
              
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="BALANCE SHEET REPORT" key="1">
           <br></br>
           <h4>NON CURRENT ASSETS </h4>
           <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset Category</th>
                      <th>Net Book Value</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.assets.map(
                    (item)=>(
                      <tr>
                      <td>{item.asset_name}</td>
                      <td>{item.categoryname}</td>
                      <td>{<CurrencyFormat value={item.net_book_value} displayType={'text'} thousandSeparator={true}/>}</td>
                      </tr>
                    ))}
                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total Non Current Assets</td>
                    <td></td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalnoncurrentassets} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                </tbody>
            </reactstrp.Table> 


            <Divider style={{borderColor:'red'}} dashed />
            <h4>CURRENT ASSETS </h4>
            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Value (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>Cash At Hand (Safe)</td>
                    <td>{<CurrencyFormat value={this.state.companyprofile.safe_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                    <tr>
                    <td>Cash At Bank</td>
                    <td>{<CurrencyFormat value={this.state.cashatbanktotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>  

                    <tr>
                    <td>Interest Receivable</td>
                    <td>{<CurrencyFormat value={this.state.totalinterestreceivable} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr> 

                    <tr>
                    <td>Loan Portfolio</td>
                    <td>{<CurrencyFormat value={this.state.totalprinciplebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td>Less Reserve (Portfolio AT Risk)</td>
                    <td>{<CurrencyFormat value={this.state.totalRiskAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td>Net Portfolio</td>
                    <td>{<CurrencyFormat value={this.state.totalprinciplebalance-this.state.totalRiskAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td>Account Receivable(Client Debts from Incomes)</td>
                    <td>{<CurrencyFormat value={this.state.totalincomes-this.state.totalincomepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total current Assets</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat 
                    value={this.state.companyprofile.safe_balance+
                      this.state.cashatbanktotal+this.state.totalinterestreceivable+(this.state.totalprinciplebalance-this.state.totalRiskAmount)+
                      (this.state.totalincomes-this.state.totalincomepayments)
                    
                    } displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>

                    <tr>
                    <td style={{fontWeight:'bolder',fontStyle:'italic',color:'blue'}}>TOTAL ASSETS</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={(this.state.companyprofile.safe_balance+
                      this.state.cashatbanktotal+this.state.totalinterestreceivable+(this.state.totalprinciplebalance-this.state.totalRiskAmount)+
                      (this.state.totalincomes-this.state.totalincomepayments))+this.state.totalnoncurrentassets} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>                    
                </tbody>
            </reactstrp.Table> 

            <Divider style={{borderColor:'red'}} dashed />
            <h2 style={{display: 'flex',  justifyContent:'center', alignItems:'center',color:'red' }}>FINANCED BY</h2>
            
            <h3>EQUITY AND LIABILITIES </h3>
            <Divider style={{borderColor:'red'}} dashed />
            <h4>LIABILITIES</h4>
            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Value (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>External Loan</td>
                    <td>{<CurrencyFormat value={this.state.externalloantotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                    <tr>
                    <td>Institutional Debts (From Expenses)</td>
                    <td>{<CurrencyFormat value={this.state.totalexpenses-this.state.totalexpensepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr> 

                    <tr>
                    <td>Member Savings</td>
                    <td>{<CurrencyFormat value={this.state.membersavingstotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>  

                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total Liabilities</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.externalloantotal+(this.state.totalexpenses-this.state.totalexpensepayments)+this.state.membersavingstotal} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>                    
                </tbody>
            </reactstrp.Table> 


              <Divider style={{borderColor:'red'}} dashed />
              <h4>EQUITY</h4>

              <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Value (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>Institutional Capital (Shares) </td>
                    <td>{<CurrencyFormat value={(this.state.companyprofile.sharevalue*this.state.membersharestotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>
                    <tr>
                    <td>Retained Earnings</td>
                    <td>{<CurrencyFormat value={this.state.companyprofile.retained_earnings} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr> 

                    <tr>
                    <td>Surplus</td>
                    <td>{<CurrencyFormat value={(this.state.yearlyincometotal-this.state.yearlyexpensetotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>  
                    <tr>
                    <td style={{fontWeight:'bolder'}}>Total Equity</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={(this.state.companyprofile.sharevalue*this.state.membersharestotal)+this.state.companyprofile.retained_earnings+(this.state.yearlyincometotal-this.state.yearlyexpensetotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>   
                    <tr>
                    <td style={{fontWeight:'bolder',fontStyle:'italic',color:'blue'}}>TOTAL EQUITY & LIABILITIES</td>
                    <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={(this.state.externalloantotal+(this.state.totalexpenses-this.state.totalexpensepayments)+this.state.membersavingstotal)+(this.state.companyprofile.sharevalue*this.state.membersharestotal)+this.state.companyprofile.retained_earnings+(this.state.yearlyincometotal-this.state.yearlyexpensetotal)} displayType={'text'} thousandSeparator={true}/>}</td>
                    </tr>                   
                </tbody>
            </reactstrp.Table> 

            <Divider style={{borderColor:'red'}} dashed />
 
            <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
            <ReactToPrint
            trigger={() => 
            <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
            Print
            </Button>
            }
            content={() => this.componentRef}
            />
            </div>


          </Panel>
          </Collapse>

        </div>

        </div>

    )

    }

  }
}

export default BalanceSheetReport; 
